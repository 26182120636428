import get from 'lodash/get';
import merge from 'lodash/merge';
import identityConfig from '../../../config/identity';
import { getPriorityTag } from '../../../utils/ads/get_priority_tag';
import { createAdDataModel } from './trackers/camp';
import { isPremium } from '../advertorial/advertorial';

export default function datalayer(
  context,
  location,
  firstLoad,
  unsanitizedURL
) {
  const siteConfig = identityConfig;

  if (siteConfig === undefined) {
    return '';
  }

  const {
    postData,
    tagPageData,
    categoryPageData,
    authorPageData,
    staticPageData,
    mostCreativePeoplePageData,
    personPageData,
    statusCode,
    ibdPageData,
    companyPageData,
    micYearPageData,
    micSectorPageData,
    videoPageData,
    playlistAllFeedData,
    type,
    config,
    supertags
  } = context;

  const getDaysSincePubdate = date =>
    Math.round((new Date().getTime() - new Date(date)) / (24 * 60 * 60 * 1000));
  const defaultDestination = get(config, 'navHistory[0]', 'unknown');
  let userAB = get(config, 'abgroup', '0');
  let failedMapping = false;

  if (!userAB) {
    userAB = undefined;
  }

  let dataLayerMapping = {
    page: {
      category: {
        primaryCategory: 'FastCompany',
        subCategory1: '',
        subCategory2: ''
      },
      pageInfo: {
        pageName: 'fastcompany:missing',
        publisher: 'fast company',
        author: 'fast company',
        authorStatus: 'staff',
        editorName: 'FC Editor',
        pageID: 'fc-default-id',
        destinationURL: `https://www.fastcompany.com/?untracked=${defaultDestination}`,
        server: 'fc-node',
        urlText: '',
        infiniteScrollIndex: 1,
        pubdate: '1995-11-01',
        daysSincePubdate: -1,
        type: '',
        contentType: '',
        editTags: '',
        referringURL: '',
        paragraphCount: '',
        fbPixelID: '1389601884702365',
        premium: false
      }
    },
    user: [
      {
        profile: [
          {
            address: {
              stateProvince: '',
              postalCode: ''
            },
            attributes: {
              isLoggedIn: get(config, 'auth.loggedIn', ''),
              bombora: {
                security: {
                  CompanyRevenue: ['Analytics', 'Personalization'],
                  CompanySize: [
                    'Analytics',
                    'Recommendations',
                    'Personalization'
                  ],
                  CompanyDomain: [
                    'Analytics',
                    'Recommendations',
                    'Personalization'
                  ],
                  CompanyInstall: ['Analytics', 'Personalization'],
                  FunctionalArea: [
                    'Analytics',
                    'Recommendations',
                    'Personalization'
                  ],
                  Profession: [
                    'Analytics',
                    'Recommendations',
                    'Personalization'
                  ],
                  Seniority: [
                    'Analytics',
                    'Recommendations',
                    'Personalization'
                  ],
                  DecisionMaker: 'Personalization',
                  Education: 'Analytics'
                }
              }
            },
            profileInfo: {
              profileID: ''
            }
          }
        ],
        segment: {
          abGroup: userAB
        }
      }
    ]
  };

  const typeLookup = {
    homepage() {
      return merge({}, dataLayerMapping, {
        page: {
          pageInfo: {
            pageName: `Homepage`,
            destinationURL: `https://www.fastcompany.com`,
            fbPixelID: '1389601884702365'
          },
          category: {
            primaryCategory: `homepage`,
            subCategory1: `homepage`,
            subCategory2: `homepage`
          }
        }
      });
    },
    post() {
      const pageName = postData.title;
      const destinationURL = postData.link;
      const tags = get(postData, 'tags');
      const eyeBrowText = getPriorityTag(tags, supertags);

      let author = '';
      if (postData.author && postData.author.name !== '') {
        author = postData.author.name;
      }

      let primaryCategory = '';
      if (
        postData.categories &&
        postData.categories.length > 0 &&
        postData.categories[0] !== undefined
      ) {
        primaryCategory = postData.categories[0].name;
      }

      let subCategory = '';
      if (
        postData.categories &&
        postData.categories.length > 0 &&
        postData.categories[1] !== undefined
      ) {
        subCategory = postData.categories[1].name;
      } else {
        subCategory = primaryCategory;
      }

      const subSubCategory = eyeBrowText || 'misc';

      function formatCategory(category) {
        let categoryCopy = category;
        categoryCopy = categoryCopy
          .replace(/[*&]/g, '')
          .replace(/\s?_advertorial/g, '')
          .toLowerCase()
          .trim();
        return categoryCopy;
      }

      let tagString = '';
      tagString = postData.tags.map(tag => tag.name).join(',');
      const pubdate = postData.formattedDate ? `${postData.formattedDate}` : '';

      let pageType = 'article';
      if (get(postData, 'posterSlideshow.length')) {
        pageType = 'slideshow';
      } else if (
        get(postData, 'hasVideoPoster') === true ||
        get(postData, 'posterVideo.feedId')
      ) {
        pageType = 'video';
      }

      const urlText = postData.slug.replace(/-/g, ' ');

      const paragraphCount = get(postData, 'totalParagraphs', '');

      const mobileInsertionAdCount =
        get(postData, 'content', []).length > 1
          ? get(postData, 'content', []).length - 1
          : 0;

      return merge({}, dataLayerMapping, {
        page: {
          pageInfo: {
            pageName,
            pageID: `fc${postData.id}`,
            destinationURL: `${destinationURL}`,
            referringURL: '',
            author,
            editorName: 'FC Editor',
            pubdate,
            daysSincePubdate: getDaysSincePubdate(pubdate),
            type: pageType,
            publisher: 'fast company',
            server: 'fc-node',
            urlText,
            editTags: tagString,
            infiniteScrollIndex: 1,
            paragraphCount,
            mobileInsertionAdCount,
            fbPixelID: '1389601884702365',
            premium: isPremium(get(postData, 'tags', []))
          },
          category: {
            primaryCategory: `${primaryCategory}`,
            subCategory1: subSubCategory.includes('_advertorial')
              ? formatCategory(subSubCategory)
              : `${subCategory}`,
            subCategory2: subSubCategory.includes('_advertorial')
              ? formatCategory(subSubCategory)
              : `${subSubCategory}`
          }
        }
      });
    },
    page() {
      return merge({}, dataLayerMapping, {
        page: {
          pageInfo: {
            pageName: `${get(staticPageData, 'slug')} | page`,
            destinationURL: `${get(siteConfig, 'url')}/${get(
              staticPageData,
              'slug'
            )}`,
            type: 'page',
            fbPixelID: '1389601884702365'
          }
        }
      });
    },
    category() {
      const category = get(categoryPageData, 'slug', 'news');
      return merge({}, dataLayerMapping, {
        page: {
          pageInfo: {
            pageName: `${categoryPageData.name} | ${categoryPageData.taxonomy}`,
            destinationURL: `${get(siteConfig, 'url')}/${category}`,
            type: 'category page',
            contentType: 'lander',
            editTags: `${category}`,
            pageID: `fc-category-${category}-${categoryPageData.id}`,
            fbPixelID: '1389601884702365'
          },
          category: {
            primaryCategory: `${category}`,
            subCategory1: `${category}`,
            subCategory2: `${category}`
          }
        }
      });
    },
    tag() {
      let tag = '';
      tag = get(tagPageData, 'slug', 'tag');
      const isAdvertorial = get(tagPageData, 'slug', '')
        .toLowerCase()
        .includes('advertorial');

      function formatTag(advertorialTag) {
        let advertorialTagCopy = advertorialTag;
        advertorialTagCopy = advertorialTagCopy
          .replace(/[*&]/g, '')
          .replace(/\s?_advertorial/g, '')
          .toLowerCase()
          .trim();
        return advertorialTagCopy;
      }

      return merge({}, dataLayerMapping, {
        page: {
          pageInfo: {
            pageName: `${tag} | tag`,
            destinationURL: `${get(siteConfig, 'url')}/section/${tag}`,
            type: 'section',
            contentType: 'collection',
            editTags: `${tag}`,
            pageID: `fc-tag-${tag}-${tagPageData.id}`,
            fbPixelID: '1389601884702365'
          },
          category: {
            primaryCategory: isAdvertorial ? 'advertorial' : tag,
            subCategory1: isAdvertorial ? formatTag(tag) : tag,
            subCategory2: isAdvertorial ? formatTag(tag) : tag
          }
        }
      });
    },
    author() {
      const authorSlug = get(authorPageData, 'slug', 'fastcompany-staff');
      const authorName = get(authorPageData, 'name', 'Fastcompany Staff');

      return merge({}, dataLayerMapping, {
        page: {
          pageInfo: {
            pageName: `${authorName} | author`,
            author: authorName,
            destinationURL: `${get(siteConfig, 'url')}/user/${authorSlug}`,
            pageID: `fc-author-${authorPageData.id}`,
            contentType: 'lander',
            type: 'author page',
            fbPixelID: '1389601884702365'
          },
          category: {
            primaryCategory: 'author',
            subCategory1: authorName,
            subCategory2: authorName
          }
        }
      });
    },
    videoPage() {
      const currentVideoData = get(videoPageData, 'data.playlist[0]', null);
      if (!currentVideoData) {
        return {};
      }

      // const videoTag = get(currentVideoData, 'category');
      const postID = get(currentVideoData, 'id');

      let tagString = '';
      tagString = currentVideoData.tags.map(tag => tag.name).join(',');

      return merge({}, dataLayerMapping, {
        page: {
          pageInfo: {
            pageName: get(currentVideoData, 'title'),
            author: `fast company video`,
            editorName: 'FC Video',
            destinationURL: get(currentVideoData, 'link'),
            pubdate: get(currentVideoData, 'formattedDate', ''),
            daysSincePubdate: getDaysSincePubdate(
              get(currentVideoData, 'formattedDate', '')
            ),
            contentType: 'video',
            type: 'video_page',
            editTags: tagString,
            pageID: `fc-jw${postID}`,
            fbPixelID: '1389601884702365'
          },
          category: {
            primaryCategory: 'video',
            subCategory1: currentVideoData.series,
            subCategory2: currentVideoData.category.toLowerCase()
          }
        }
      });
    },
    companyPage() {
      const companyName = get(companyPageData, 'data.tags[0].name', '');
      const companySlug = get(companyPageData, 'data.tags[0].slug', '');
      const companyId = get(companyPageData, 'data.tags[0].id', '');

      return merge({}, dataLayerMapping, {
        page: {
          pageInfo: {
            destinationURL: `${get(siteConfig, 'url')}/company/${companySlug}`,
            pageName: `${companyName} | company`,
            pageID: `fc-company-${companyId}`,
            contentType: 'company profile',
            type: 'company page',
            pubdate: '2019-02-20',
            daysSincePubdate: getDaysSincePubdate('2019-02-20'),
            editTags: `${companyName},mic,most innovative companies`,
            fbPixelID: '1389601884702365'
          },
          category: {
            primaryCategory: 'award',
            subCategory1: 'most innovative companies',
            subCategory2: 'company'
          }
        }
      });
    },
    mostCreativePeoplePage() {
      const year = get(mostCreativePeoplePageData, 'year', '');

      return merge({}, dataLayerMapping, {
        page: {
          pageInfo: {
            pageName: year ? `mcp ${year} | lander` : `mcp default | lander`,
            destinationURL: year
              ? `${get(siteConfig, 'url')}/most-creative-people/${year}`
              : `${get(siteConfig, 'url')}/most-creative-people`,
            editTags: `${year},mcp,most creative people`,
            contentType: 'lander',
            type: 'mcp page',
            pageID: `fc-mcp-${year}`,
            fbPixelID: '1389601884702365'
          },
          category: {
            primaryCategory: 'award',
            subCategory1: 'most creative people',
            subCategory2: 'year'
          }
        }
      });
    },
    personPage() {
      const personSlug = get(personPageData, 'data.rows[0].url', '');
      const personName = `${get(
        personPageData,
        'data.rows[0].first_name',
        ''
      )} ${get(personPageData, 'data.rows[0].last_name', '')}`;
      const personCompanies = get(personPageData, 'data.rows[0].companies', '');
      const personId = get(personPageData, 'data.rows[0].id', '');

      let tagString = '';
      tagString = personCompanies.map(company => company.name).join(',');

      return merge({}, dataLayerMapping, {
        page: {
          pageInfo: {
            pageName: `${personName} | person`,
            contentType: 'profile',
            destinationURL: `${get(siteConfig, 'url')}/person/${personSlug}`,
            type: 'person',
            editTags: `${personName},${tagString},mcp,most creative people`,
            pageID: `fc-person-${personId}`,
            fbPixelID: '1389601884702365'
          },
          category: {
            primaryCategory: 'awards',
            subCategory1: 'most creative people',
            subCategory2: 'person'
          }
        }
      });
    },
    micYearPage() {
      const year = get(micYearPageData, 'year', '');

      return merge({}, dataLayerMapping, {
        page: {
          pageInfo: {
            pageName: year ? `mic ${year} | lander` : `mic default | lander`,
            destinationURL: year
              ? `${get(siteConfig, 'url')}/most-innovative-companies/${year}`
              : `${get(siteConfig, 'url')}/most-innovative-companies`,
            editTags: `${year},mic,most innovative companies`,
            contentType: 'lander',
            type: 'mic page',
            pageID: `fc-mic-${year}`,
            fbPixelID: '1389601884702365'
          },
          category: {
            primaryCategory: 'award',
            subCategory1: 'most innovative companies',
            subCategory2: 'year'
          }
        }
      });
    },
    mic2020YearPage() {
      const year = '2020';

      return merge({}, dataLayerMapping, {
        page: {
          pageInfo: {
            pageName: `mic ${year} | lander`,
            destinationURL: `${get(
              siteConfig,
              'url'
            )}/most-innovative-companies/${year}`,
            editTags: `${year},mic,most innovative companies`,
            contentType: 'lander',
            type: 'mic page',
            pageID: `fc-mic-${year}`,
            fbPixelID: '1389601884702365'
          },
          category: {
            primaryCategory: 'award',
            subCategory1: 'most innovative companies',
            subCategory2: 'year'
          }
        }
      });
    },
    micSectorPage() {
      const year = get(micSectorPageData, 'year', '');
      const sector = get(micSectorPageData, 'sector', '');

      return merge({}, dataLayerMapping, {
        page: {
          pageInfo: {
            pageName: `Most Innovative Companies ${year} ${sector}`,
            destinationURL: `${get(
              siteConfig,
              'url'
            )}/most-innovative-companies/${year}/${sector}`,
            editTags: `${sector},${year},mic,most innovative companies`,
            contentType: 'lander',
            type: 'mic sector page',
            pageID: `fc-mic-${sector}`,
            fbPixelID: '1389601884702365'
          },
          category: {
            primaryCategory: 'award',
            subCategory1: 'most innovative companies',
            subCategory2: 'sector'
          }
        }
      });
    },
    ibdPage() {
      // i.e. http://fastcompany.local:8880/innovation-by-design/2018
      const year = get(ibdPageData, 'year', '');
      return merge({}, dataLayerMapping, {
        page: {
          pageInfo: {
            pageName: year ? `ibd ${year} | lander` : `ibd default | lander`,
            destinationURL: year
              ? `${get(siteConfig, 'url')}/innovation-by-design/${year}`
              : `${get(siteConfig, 'url')}/innovation-by-design`,
            editTags: `${year},ibd,innovation by design`,
            pageID: `fc-ibd-${year}`,
            type: 'ibd year page',
            contentType: 'lander',
            fbPixelID: '1389601884702365'
          },
          category: {
            primaryCategory: 'awards',
            subCategory1: 'innovation by design',
            subCategory2: 'lander'
          }
        }
      });
    },
    search() {
      const searchQuery = get(unsanitizedURL.split('?searchkey='), '[1]', '');

      return merge({}, dataLayerMapping, {
        page: {
          pageInfo: {
            pageName: `${searchQuery} | search`,
            destinationURL: `${get(siteConfig, 'url')}/search`,
            pageID: `fc-search-${searchQuery}`,
            editTags: `${searchQuery},search`,
            type: 'search page',
            contentType: 'lander',
            fbPixelID: '1389601884702365'
          },
          category: {
            primaryCategory: 'search',
            subCategory1: searchQuery,
            subCategory2: 'lander'
          }
        }
      });
    },
    videoPlaylistPage() {
      // i.e. http://fastcompany.local:8880/videos/playlist/trending/jVdHZUiT/1
      const feedid = get(playlistAllFeedData, 'data.feedid');
      const name = get(playlistAllFeedData, 'data.kind').toLowerCase();
      const slug = get(playlistAllFeedData, 'data.slug');

      return merge({}, dataLayerMapping, {
        page: {
          pageInfo: {
            pageID: `fc-video-playlist-${feedid}`,
            pageName: `${name} | video playlist`,
            destinationURL: `https://www.fastcompany.com/videos/playlist/${slug}/${feedid}`,
            type: 'video playlist',
            contentType: 'lander',
            fbPixelID: '1389601884702365'
          },
          category: {
            primaryCategory: 'video',
            subCategory1: name,
            subCategory2: 'playlist'
          }
        }
      });
    },
    videoIndexPage() {
      return merge({}, dataLayerMapping, {
        page: {
          pageInfo: {
            pageID: `fc-video-lander`,
            pageName: `Video Landing Page`,
            destinationURL: `${get(siteConfig, 'url')}/videos`,
            type: 'video page',
            contentType: 'lander',
            fbPixelID: '1389601884702365'
          },
          category: {
            primaryCategory: 'video',
            subCategory1: 'video',
            subCategory2: 'lander'
          }
        }
      });
    },
    staticPage() {
      if (unsanitizedURL.includes('/newsletters')) {
        // Newsletters Lander | ../newsletters
        return merge({}, dataLayerMapping, {
          page: {
            pageInfo: {
              pageID: `fc-newsletter-lander`,
              pageName: `Newsletters Landing Page`,
              destinationURL: `${get(siteConfig, 'url')}/newsletters`,
              type: 'newsletter page',
              contentType: 'lander',
              fbPixelID: '1389601884702365'
            },
            category: {
              primaryCategory: 'Systems',
              subCategory1: 'newsletter',
              subCategory2: 'lander'
            }
          }
        });
      }
      return merge({}, dataLayerMapping);
    },
    sitemapIndexPage() {
      return merge({}, dataLayerMapping, {
        page: {
          pageInfo: {
            pageID: `fc-sitemap-lander`,
            pageName: `Site Map Index Page`,
            destinationURL: `${get(siteConfig, 'url')}/sitemap`,
            type: 'sitemap page',
            contentType: 'lander',
            fbPixelID: '1389601884702365'
          },
          category: {
            primaryCategory: 'sitemap',
            subCategory1: 'sitemap',
            subCategory2: 'lander'
          }
        }
      });
    },
    sitemapPostPage() {
      return merge({}, dataLayerMapping, {
        page: {
          pageInfo: {
            pageID: `fc-sitemap-post`,
            pageName: `Site Map Post Page`,
            destinationURL: `${get(siteConfig, 'url')}/sitemap`,
            type: 'sitemap post page',
            contentType: 'lander',
            fbPixelID: '1389601884702365'
          },
          category: {
            primaryCategory: 'sitemap',
            subCategory1: 'sitemap',
            subCategory2: 'lander'
          }
        }
      });
    },
    pianoPage() {
      return merge({}, dataLayerMapping, {
        page: {
          pageInfo: {
            pageID: `fc-piano-page`,
            pageName: `Piano Page`,
            type: 'piano page',
            destinationURL: defaultDestination,
            contentType: 'lander',
            fbPixelID: '1389601884702365'
          },
          category: {
            primaryCategory: 'Systems',
            subCategory1: 'pianoPage',
            subCategory2: 'lander'
          }
        }
      });
    },
    applyPage() {
      return merge({}, dataLayerMapping, {
        page: {
          pageInfo: {
            pageID: `fc-apply-page`,
            pageName: `Apply Page`,
            type: 'apply page',
            destinationURL: defaultDestination,
            contentType: 'lander',
            fbPixelID: '1389601884702365'
          },
          category: {
            primaryCategory: 'Systems',
            subCategory1: 'applyPage',
            subCategory2: 'lander'
          }
        }
      });
    }
  };

  if (typeLookup[type]) {
    try {
      dataLayerMapping = typeLookup[type]();
    } catch (err) {
      failedMapping = true;
    }
  }

  // Handle 404 pages
  if (failedMapping || (statusCode && statusCode === 404)) {
    dataLayerMapping = merge({}, dataLayerMapping, {
      page: {
        pageInfo: {
          pageName: `status-${statusCode}`,
          type: 'status',
          destinationURL: defaultDestination,
          contentType: 'lander',
          editTags: `${statusCode}`,
          pageID: `fc-${statusCode}`,
          fbPixelID: '1389601884702365'
        },
        category: {
          primaryCategory: '404',
          subCategory1: '404',
          subCategory2: '404'
        }
      }
    });
  }

  // === ads === //
  createAdDataModel(context);

  return `<script>
      window.digitalData = {
        page: ${JSON.stringify(get(dataLayerMapping, 'page', ''))},
        user: ${JSON.stringify(get(dataLayerMapping, 'user', ''))}
      };
    </script>
  `;
}
