import identityConfig from '../../../../config/identity';

export default function skimlinks() {
  const siteConfig = identityConfig;

  if (siteConfig === undefined) {
    return '';
  }

  return `<script type="text/javascript" src="https://s.skimresources.com/js/122276X1583643.skimlinks.js" async></script>`;
}
