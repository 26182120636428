export default function pushly() {
  const { PUSHLY_TESTING_DOMAIN_KEY, PUSHLY_DOMAIN_KEY } = process.env;

  // Using environment variables to set the domain for testing and production
  let pushlyDomainKey = PUSHLY_DOMAIN_KEY; // eslint-disable-line no-unused-vars
  let pushlyDomainKeyString = 'QnLGoHTBv8oemPGDChVzn4Jj07xbzuWlHZZk';
  if (process.env.NODE_ENV === 'development' || process.env.STAGE === 'true') {
    pushlyDomainKey = PUSHLY_TESTING_DOMAIN_KEY;
    pushlyDomainKeyString = 'nsAqvdBTjX7C9P6VX7dFWc3HCR88sXmqJUHE';
  }

  return `
  <!-- START PUSHLY //-->
  <script src="https://cdn.p-n.io/pushly-sdk.min.js?domain_key=${pushlyDomainKeyString}" async></script>
  <script>
    window.PushlySDK = window.PushlySDK || [];
    function pushly() { window.PushlySDK.push(arguments) }
    pushly('load', {
      domainKey: '${pushlyDomainKeyString}',
    });
  </script>
  <!-- END PUSHLY //-->
  `;
}
