import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';
import last from 'lodash/last';
import isFunction from 'lodash/isFunction';
import log from '../../../services/logger_service';
import facebookPixel from './trackers/facebookpixel';
import googleAnalytics from './trackers/ga';
import nielsen from './trackers/nielsen';
import parsely from './trackers/parsely';
import pingdom from './trackers/pingdom';
import structuredData from './trackers/structured_data';
import skimlinks from './trackers/skimlinks';
import camp from './trackers/camp';
import queryly from './trackers/queryly';
import chartbeat from './trackers/chartbeat';
import bombora from './trackers/bombora';
import piano from './trackers/piano';
import knotch from './trackers/knotch';
import datalayer from './datalayer';
import pinterest from './trackers/pinterest';
import pushly from './trackers/pushly';
import blueconic from './trackers/blueconic';
import gpt from './trackers/gpt';
import adlightning from './trackers/adlightning';
import keywee from './trackers/keywee';
import polyfill from './trackers/polyfill';

/**
 * An analytics component that's broken up to 2 sections, header and footer
 * Pass in the name of the tags you want to display in props
 * and the system will determine what to render
 */
class Analytics extends Component {
  constructor(props) {
    super(props);
    this.datalayer = datalayer;
    this.camp = camp;
    this.facebookPixel = facebookPixel;
    this.googleAnalytics = googleAnalytics;
    this.nielsen = nielsen;
    this.parsely = parsely;
    this.pingdom = pingdom;
    this.structuredData = structuredData;
    this.skimlinks = skimlinks;
    this.queryly = queryly;
    this.knotch = knotch;
    this.chartbeat = chartbeat;
    this.bombora = bombora;
    this.piano = piano;
    this.pinterest = pinterest;
    this.pushly = pushly;
    this.blueconic = blueconic;
    this.gpt = gpt;
    this.adlightning = adlightning;
    this.keywee = keywee;
    this.polyfill = polyfill;
  }

  shouldComponentUpdate() {
    return false;
  }

  renderAnalyticsDangerously(
    context,
    location,
    listOfAnalytics,
    firstLoad,
    url,
    isFBIA
  ) {
    // Create a string of all analytics code
    let combinedAnalytics = '';
    if (!isFBIA) {
      combinedAnalytics = `<!-- ANALYTICS ${location.toUpperCase()} START -->`;
    }
    listOfAnalytics.forEach(analyticTag => {
      if (isFunction(this[analyticTag])) {
        combinedAnalytics += this[analyticTag](
          context,
          location,
          firstLoad,
          url,
          isFBIA
        );
      }
    });

    if (!isFBIA) {
      combinedAnalytics += `<!-- ANALYTICS ${location.toUpperCase()} END -->`;
    }
    return { __html: combinedAnalytics };
  }

  render() {
    const { location, listOfAnalytics } = this.props;
    const firstLoad = this.props.initialPageLoad;
    let context = null;
    if (firstLoad) {
      try {
        const currentState = JSON.parse(get(this.props, 'state'));
        const top = get(currentState, 'featuredFeed.data.top') || [];
        const featured = get(currentState, 'featuredFeed.data.featured') || [];
        const homeList = top.concat(featured);

        const categoryTop = get(currentState, 'categoryFeaturedFeed.top') || [];
        const categoryFeatured =
          get(currentState, 'categoryFeaturedFeed.featured') || [];
        const categoryHomeList = categoryTop.concat(categoryFeatured);

        const tagTop = get(currentState, 'tagFeaturedFeed.top') || [];
        const tagFeatured = get(currentState, 'tagFeaturedFeed.featured') || [];
        const tagHomeList = tagTop.concat(tagFeatured);

        const playlistAllFeedData = get(currentState, 'playlistAllFeed') || [];
        context = get(currentState, 'context');

        context.config = get(currentState, 'config');
        context.postData =
          get(currentState, 'post.data.post', null) ||
          get(currentState, 'previewPost.data.post', null);
        context.tagPageData = get(currentState, 'tagFeaturedFeed.tag', null);
        context.categoryPageData = get(
          currentState,
          'categoryFeaturedFeed.category',
          null
        );
        context.authorPageData = get(currentState, 'author.data', null);
        context.playlistAllFeedData = playlistAllFeedData;

        context.authorAllFeed = get(
          currentState,
          'authorAllFeed.data.posts',
          []
        );

        context.searchPageData = get(currentState, 'searchPage', null);
        context.staticPageData = get(
          currentState,
          'customPage.data.page[0]',
          null
        );
        context.statusCode = get(currentState, 'status.code');
        context.homeList = homeList || null;
        context.categoryHomeList = categoryHomeList || null;
        context.tagHomeList = tagHomeList || null;
        context.mostCreativePeoplePageData = get(
          currentState,
          'mostCreativePeople',
          null
        );
        context.personPageData = get(currentState, 'person', null);
        context.ibdPageData = get(
          currentState,
          'franchisePage.data.externalList',
          null
        );
        context.giftGuideData = get(currentState, 'giftGuide', null);
        context.companyPageData = get(currentState, 'tagAllFeed', null);
        context.micYearPageData = get(currentState, 'micYear', null);
        context.micYearModernPageData = get(currentState, 'micYearPage', null);
        context.micSectorPageData = get(currentState, 'micSector', null);
        context.videoPageData = get(currentState, 'jwVideo', null);
        context.videoIndexPageData = get(currentState, 'jwVideoIndex', null);
        context.magazineArchivePageData = get(
          currentState,
          'magazineArchivePage',
          null
        );
        context.productPageData = get(currentState, 'product', null);
        context.mic2020YearPage = get(currentState, 'mic2020Companies', null);
        context.brandsThatMatterPage = get(
          currentState,
          'brandsThatMatterPage.data',
          null
        );
        context.micHubPage = get(currentState, 'micHubPage', null);
        context.worldChangingIdeasPage = get(
          currentState,
          'worldChangingIdeasPage',
          null
        );
        context.queer50Page = get(currentState, 'queer50', null);
        context.hubPage = get(currentState, 'hubPage', null);
        context.recognitionHubPage = get(
          currentState,
          'recognitionHubPage.data',
          null
        );
        context.applyPage = get(currentState, 'applyPage', null);
        context.franchisePage = get(currentState, 'franchisePage', null);
        context.supertags = get(currentState, 'supertags.supertags', null);
      } catch (e) {
        log.error(e, 'Unable to parse context for the page');
      }
      /* eslint-disable */
      return (
        <div
          dangerouslySetInnerHTML={this.renderAnalyticsDangerously(context, location, listOfAnalytics, firstLoad, this.props.url, this.props.isFBIA)}
        />
      );
    }
    return null;
  }
}

Analytics.defaultProps = {
  isFBIA: false
};

Analytics.propTypes = {
  initialPageLoad: PropTypes.bool.isRequired,
  location: PropTypes.string.isRequired,
  listOfAnalytics: PropTypes.arrayOf(PropTypes.string).isRequired,
  isFBIA: PropTypes.bool
};

function mapStateToProps(state) {
  const { initialPageLoad } = state.config;
  const currentState = initialPageLoad ? JSON.stringify(state) : '';
  return {
    url: last(get(state, 'config.navHistory', [])),
    state: currentState,
    initialPageLoad
  };
}

export default connect(mapStateToProps)(Analytics);
