import identityConfig from '../../../../config/identity';
import get from 'lodash/get';

export default function chartbeat(context) {
  const { type } = context;
  const siteConfig = identityConfig;
  if (siteConfig === undefined) {
    return '';
  }

  // define dynamic Chartbeat variables
  let sections = '';
  let author = '';
  let title = '';

  switch (type) {
    case 'post': {
      const currentSections = [];
      const defaultSection = 'Editorial Content';
      const topCategory = get(context, 'postData.categories[0].name', '');

      // If the top category is "Advertorial" then use that as the section
      if (topCategory === 'Advertorial') {
        currentSections.push(topCategory);
      } else {
        currentSections.push(defaultSection, topCategory);
      }

      sections = currentSections.join(',');
      author = get(context, 'postData.author.name', '');
      title = get(context, 'postData.title', '');
      break;
    }
    case 'homepage':
      title = 'Homepage';
      break;
    case 'micYearPage':
      sections = 'Mic Year Page';
      title = get(context, 'micYearPageData.year', '');
      break;
    case 'micSectorPage':
      sections = 'Mic Sector Page';
      title = get(context, 'micYearPageData.year', '');
      break;
    case 'videoIndexPage':
      title = 'Video Index Page';
      break;
    case 'videoPage':
      sections = 'Video';
      title = get(context, 'videoPageData.data.title', '');
      break;
    case 'category':
      sections = 'Category';
      title = get(context, 'categoryPageData.data.title', '');
      break;
    case 'giftGuidePage':
      sections = 'Gift Guide';
      title = `Gift Guide ${get(context, 'giftGuideData.year', '')}`;
      break;
    default:
      break;
  }

  // Handle stripping of any special characters from the values
  const sanitizeValue = value => value.replace("'", '');

  return `
    <script type='text/javascript'>
      (function() {
        /** CONFIGURATION START **/
        var _sf_async_config = window._sf_async_config = (window._sf_async_config || {});

        _sf_async_config.uid = 2768;
        _sf_async_config.domain = 'fastcompany.com';
        _sf_async_config.flickerControl = false;
        _sf_async_config.useCanonical = true;
        _sf_async_config.useCanonicalDomain = true;
        _sf_async_config.sections = '${sanitizeValue(sections)}';
        _sf_async_config.authors = '${sanitizeValue(author)}';
        _sf_async_config.title = '${sanitizeValue(title)}';
        /** CONFIGURATION END **/
        function loadChartbeat() {
            var e = document.createElement('script');
            var n = document.getElementsByTagName('script')[0];
            e.type = 'text/javascript';
            e.async = true;
            e.src = '//static.chartbeat.com/js/chartbeat.js';
            n.parentNode.insertBefore(e, n);
        }
        var oldonload = window.onload;
        window.onload = (typeof window.onload != 'function') ? loadChartbeat : function() { oldonload(); loadChartbeat(); };
      })();
    </script>
  `;
}
