import get from 'lodash/get';
import { getPriorityTag } from '../../../../utils/ads/get_priority_tag';

const { PIANO_AID, PIANO_URL, PIANO_API_URL } = process.env;

const buildCustomParams = context => {
  const {
    type,
    postData,
    categoryPageData,
    tagPageData,
    authorPageData,
    supertags,
    config
    // searchPageData,
    // staticPageData,
    // mostCreativePeoplePageData,
    // personPageData,
    // statusCode,
    // ibdPageData,
    // companyPageData,
    // micYearPageData,
    // micSectorPageData,
    // videoPageData,
    // productPageData,
    // playlistAllFeedData,
  } = context;

  // DEBUG for your data inputs!
  // console.log('🎹 Debug: Page Context Object --', '\n', context);

  const typeLookup = {
    post() {
      const tags = get(postData, 'tags', []);
      const eyeBrowText = getPriorityTag(tags, supertags);

      let author = '';
      if (postData.author && postData.author.name !== '') {
        author = postData.author.name;
      }

      let primaryCategory = '';
      if (
        postData.categories &&
        postData.categories.length > 0 &&
        postData.categories[0] !== undefined
      ) {
        primaryCategory = postData.categories[0].name;
      }

      let subCategory = '';
      if (
        postData.categories &&
        postData.categories.length > 0 &&
        postData.categories[1] !== undefined
      ) {
        subCategory = postData.categories[1].name;
      } else {
        subCategory = primaryCategory;
      }

      const subSubCategory = eyeBrowText || 'misc';
      const pubdate = get(postData, 'formattedDate', '')
        ? `${get(postData, 'formattedDate', '')}`
        : '';

      const isSponsored = !!tags.filter(tag =>
        tag.name.includes('_advertorial')
      ).length;

      let pageType = 'article';
      if (get(postData, 'posterSlideshow.length')) {
        pageType = 'slideshow';
      } else if (
        get(postData, 'hasVideoPoster') === true ||
        get(postData, 'posterVideo.feedId')
      ) {
        pageType = 'video';
      }

      return `
        tp.push(["setContentSection", "${primaryCategory}"]);
        // tp.push(["setContentSection", "${subCategory}"]);
        tp.push(["setContentAuthor", "${author}"]);
        tp.push(["setContentCreated", "${pubdate}"]);
        tp.push(["setCustomVariable", "primaryCategory", "${primaryCategory}"]);
        tp.push(["setCustomVariable", "subCategory1", "${subCategory}"]);
        tp.push(["setCustomVariable", "subCategory2", "${subSubCategory}"]);
        tp.push(["setCustomVariable", "contentType", "${pageType}"]);
        tp.push(["setCustomVariable", "articleType", "${pageType}"]);
        tp.push(["setCustomVariable", "isSponsored", "${isSponsored}"]);
        tp.push(["setCustomVariable", "isCrawler", "${config?.deviceType ===
          'crawler'}"]);
        tp.push(["setTags", [${get(postData, 'tags', [])
          .map(item => `"${get(item, 'name', '').replace(/"/g, '')}"`)
          .join()}]]);
      `;
    },
    category() {
      const pageType = 'category';
      const category = get(categoryPageData, 'slug', 'news');
      return `
        tp.push(["setContentSection", "${category}"]);
        tp.push(["setCustomVariable", "primaryCategory", "${category}"]);
        tp.push(["setCustomVariable", "contentType", "${pageType}"]);
        tp.push(["setTags", ["categoryPage", "${category}"]]);
      `;
    },
    tag() {
      const pageType = 'tag';
      const tagName = get(tagPageData, 'slug', 'news');

      return `
        tp.push(["setContentSection", "${tagName}"]);
        tp.push(["setCustomVariable", "primaryCategory", "${tagName}"]);
        tp.push(["setCustomVariable", "contentType", "${pageType}"]);
        tp.push(["setTags", ["tagPage", "${tagName}"]]);
      `;
    },
    homepage() {
      const pageType = 'homepage';
      return `
        tp.push(["setCustomVariable", "contentType", "${pageType}"]);
        tp.push(["setContentSection", "${pageType}"]);
        tp.push(["setTags", ["${pageType}"]]);
      `;
    },
    author() {
      const pageType = 'author';
      const author = get(authorPageData, 'name', 'Unknown Author');
      return `
        tp.push(["setCustomVariable", "contentType", "${pageType}"]);
        tp.push(["setContentSection", "${pageType}"]);
        tp.push(["setContentAuthor", "${author}"]);
        tp.push(["setTags", ["${pageType}"]]);
      `;
    },
    micYearPage() {
      const pageType = 'recognition';
      return `
        tp.push(["setCustomVariable", "contentType", "${pageType}"]);
        tp.push(["setContentSection", "${pageType}"]);
        tp.push(["setTags", ["${pageType}Page", "franchisePage", "mic"]]);
      `;
    },
    bestWorkplacesPage() {
      const pageType = 'recognition';
      return `
        tp.push(["setCustomVariable", "contentType", "${pageType}"]);
        tp.push(["setContentSection", "${pageType}"]);
        tp.push(["setTags", ["${pageType}Page", "franchisePage", "best workplaces"]]);
      `;
    },
    mostCreativePeoplePage() {
      const pageType = 'recognition';
      return `
        tp.push(["setCustomVariable", "contentType", "${pageType}"]);
        tp.push(["setContentSection", "${pageType}"]);
        tp.push(["setTags", ["${pageType}Page", "franchisePage", "mcp"]]);
      `;
    },
    queer50Page() {
      const pageType = 'recognition';
      return `
        tp.push(["setCustomVariable", "contentType", "${pageType}"]);
        tp.push(["setContentSection", "${pageType}"]);
        tp.push(["setTags", ["${pageType}Page", "franchisePage", "q50"]]);
      `;
    },
    ibdPage() {
      const pageType = 'recognition';
      return `
        tp.push(["setCustomVariable", "contentType", "${pageType}"]);
        tp.push(["setContentSection", "${pageType}"]);
        tp.push(["setTags", ["${pageType}Page", "franchisePage", "ibd"]]);
      `;
    },
    editorialFranchise() {
      const pageType = 'recognition';
      return `
        tp.push(["setCustomVariable", "contentType", "${pageType}"]);
        tp.push(["setContentSection", "${pageType}"]);
        tp.push(["setTags", ["${pageType}Page", "franchisePage"]]);
      `;
    },
    'wci-list': () => {
      const pageType = 'recognition';
      return `
        tp.push(["setCustomVariable", "contentType", "${pageType}"]);
        tp.push(["setContentSection", "${pageType}"]);
        tp.push(["setTags", ["${pageType}Page", "franchisePage", "wci"]]);
      `;
    }
  };

  if (typeLookup[type]) {
    return typeLookup[type]();
  }

  return '';
};

export default function pianoInit(context = {}) {
  const customParamString = buildCustomParams(context);

  return `
    <!-- Piano -->
    <script>
      (function() {
        tp = window["tp"] || [];
        tp.push(["setAid", '${PIANO_AID}']);
        tp.push(["setEndpoint", 'https://${PIANO_API_URL}/api/v3']);

        ${customParamString}

        tp.push(["setUseTinypassAccounts", false ]);
        tp.push(["setUsePianoIdUserProvider", true ]);

        // Execute when the page is initially loaded
        tp.push(["init", function() {
            tp.pianoId.init({
                displayMode: 'modal',
                width: 550
            });
            tp.experience.init();
        }]);

        (function(src){
          var a=document.createElement("script");
          a.type="text/javascript";
          a.async=true;
          a.src=src;
          var b=document.getElementsByTagName("script")[0];
          b.parentNode.insertBefore(a,b)
        })("//${PIANO_URL}/xbuilder/experience/load?aid=${PIANO_AID}&ccb=z2x");
      })();
    </script>

    <!-- attempt adblk -->
    <script>
      document.cookie = "__adblocker=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
      var setNptTechAdblockerCookie = function(adblocker) {
          var d = new Date();
          d.setTime(d.getTime() + 60 * 5 * 1000);
          document.cookie = "__adblocker=" + (adblocker ? "true" : "false") + "; expires=" + d.toUTCString() + "; path=/";
      };
      var script = document.createElement("script");
      script.setAttribute("async", true);
      script.setAttribute("src", "//www.npttech.com/advertising.js");
      script.setAttribute("onerror", "setNptTechAdblockerCookie(true);");
      document.getElementsByTagName("head")[0].appendChild(script);
    </script>
  `;
}
