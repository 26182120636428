import get from 'lodash/get';
import identityConfig from '../../../../config/identity';

export default function pingdom() {
  const siteConfig = identityConfig;
  if (siteConfig === undefined) {
    return '';
  }
  const pingdomConfig = get(siteConfig, 'pingdom');
  const { id } = pingdomConfig;

  return `
    <script>
      var _prum = [['id', '${id}'],
      ['mark', 'firstbyte', (new Date()).getTime()]];
      (function() {
      var s = document.getElementsByTagName('script')[0]
      , p = document.createElement('script');
      p.async = 'async';
      p.src = '//rum-static.pingdom.net/prum.min.js';
      s.parentNode.insertBefore(p, s);
      })();
    </script>
  `;
}
