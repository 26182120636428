// Merge content body together, it is used for post content field
function mergeContent(content) {
  let html = '';

  content.forEach(markup => {
    html += markup.join('');
  });

  return html;
}

export default mergeContent;
