import get from 'lodash/get';
import identityConfig from '../../../../config/identity';

export default function nielsen(context, location) {
  const siteConfig = identityConfig;
  if (siteConfig === undefined) {
    return '';
  }
  const nielsenConfig = get(siteConfig, 'nielsen');
  const { clientId } = nielsenConfig;

  if (location === 'header') {
    return `
      <script type="text/javascript">
        (function () {
          var d = new Image(1, 1);
          d.onerror = d.onload = function () {
          d.onerror = d.onload = null;
          };
          d.src = ["//secure-us.imrworldwide.com/cgi-bin/m?ci=${clientId}&cg=0&cc=1&si=", escape(window.location.href), "&rp=", escape(document.referrer), "&ts=compact&rnd=", (new Date()).getTime()].join('');
        })();

        function Nielsen_Event() {
          var d = new Image(1, 1);
          d.onerror = d.onload = function () { d.onerror = d.onload = null; };
          d.src = ["//secure-us.imrworldwide.com/cgi-bin/m?ci=${clientId}&cg=0&cc=1&si=", escape(window.location.href),
          "&rp=", escape(document.referrer),
          "&ts=compact&c0=usergen,1&rnd=",(new Date()).getTime()].join('');
        };
      </script>
    `;
  }

  return `<noscript><div><img src="//secure-us.imrworldwide.com/cgi-bin/m?ci=${clientId}&amp;cg=0&amp;cc=1&amp;ts=noscript" width="1" height="1" alt="" /></div></noscript>`;
}
